<template>
  <b-modal
    :visible="show"
    id="modal-question-update-lesson-module"
    ref="modal-question-update-lesson-module"
    centered
    :title="'Cập nhật chủ đề câu hỏi'"
    size="sm"
    @hide="resetModalLessonModule"
  >
    <b-container>
      <b-overlay :show="loading">
        <form @submit.prevent="handleValidate">
          <b-row>
            <b-col cols="12">
              <basic-select
                :required="true"
                label="Chủ đề"
                class="lesson-edit-page__dropdown-filter"
                placeholder="---Chọn chủ đề---"
                name="module"
                :solid="false"
                :value.sync="form.module"
                :options="moduleOptions"
                value-label="text"
                track-by="value"
                :state="validateState('module')"
                :invalidFeedback="errors.first('module')"
                v-validate="'required'"
                data-vv-as="Chủ đề"
                @update:value="selected($event, 'module', moduleOptions)"
              />
            </b-col>
          </b-row>
        </form>
      </b-overlay>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          class="btn btn-plain ml-2"
          href="#"
          :disabled="loading"
          @click.stop="handleCancel"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Huỷ
        </b-button>
        <b-button
          class="btn btn-success ml-2"
          href="#"
          :disabled="loading"
          @click.stop="handelValidation"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Cập nhật
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { GET_QUESTION } from '@/core/services/store/question.module';
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('question');
export default {
  name: 'ModalLessonModule',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  created() {
    this.getQuestion();
  },
  async mounted() {
    await this.loadPrepareData();
  },
  data() {
    return {
      loading: false,
      question: {},
      form: {
        module: null,
      },
      moduleOptions: [],
    };
  },
  methods: {
    ...mapActions({
      GET_QUESTION,
    }),
    resetModalLessonModule() {
      this.question = {};
      this.form = {
        module: null,
      };
      this.$emit('reset-lesson-module');
    },
    handleCancel() {
      this.resetModalLessonModule();
      this.$bvModal.hide('modal-question-update-lesson-module');
    },
    async getQuestion(showLoading = true) {
      showLoading && (this.loading = true);
      const { error, data } = await this.GET_QUESTION(this.id);
      if (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } else {
        this.question = data;
        this.form.module = data.lessonModule
          ? {
              text: data.lessonModule.name,
              value: data.lessonModule.id,
            }
          : null;
      }
      showLoading && (this.loading = false);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    async loadPrepareData() {
      try {
        this.loading = true;
        let apiURI = `/LearningPost/PrepareCreateUpdateFormItem`;
        let resMetadata = await this.$api.get(apiURI);
        let dataMeta = resMetadata.data;
        this.moduleOptions = this.getDropdownOptions(dataMeta.lessonModules);
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    getDropdownOptions(array) {
      return array.map((item) => {
        return {
          text: item.text,
          value: item.value,
        };
      });
    },
    handelValidation() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.handleSubmit();
      });
    },
    async handleSubmit() {
      let payload = {
        lessonModuleId: this.form.module?.value,
        id: this.id,
      };
      try {
        await this.$api.put('Question/UpdateLessionModuleQuestion', payload);
        this.$bvModal.hide('modal-question-update-lesson-module');
        this.$emit('reload-data');
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật chủ đề thành công',
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    selected(value, formProperty, optionsArr) {
      this.form[formProperty] = value
        ? optionsArr.find((el) => el.value == value)
        : null;
      if (this.form[formProperty]) {
        this.$validator.reset();
      }
    },
  },
};
</script>
